
export default {
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    computed: {
        showBadge() {
            return Boolean(this.badgeType);
        },
        badgeType() {
            if (this.product.items && this.product.items.length) {
                if (this.product.items.every((item) => this.checkIfExternal(item.product))) {
                    return 'external';
                }
            } else if (this.checkIfExternal(this.product)) {
                return 'external';
            }

            return null;
        },
        badgeContent() {
            if (this.badgeType === 'external') {
                return this.$t('catalog.badge.external');
            }

            return '';
        },
    },
    methods: {
        checkIfExternal(product) {
            return product.is_external;
        },
    },
};
