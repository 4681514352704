
import { headroom } from 'vue-headroom';
import ClickOutside from 'vue-click-outside';

export default {
    components: {
        headroom,
    },
    directives: {
        ClickOutside,
    },
    data() {
        return {
            mobileMenuOpen: false,
            isOpen: false,
            flyout: 0,
            subFlyout: 0,
            currentMobileCategory: 0,
            currentMobileSubCategory: 0,
            currentMobileLevel: 'root',
            rootCategory: [],
            offset: 0,
            promoInfo: null,
            openedSubCategories: [],
        };
    },
    async fetch() {
        const {
            data: { categories },
        } = await this.$axios.$get('/topmenu');
        const isMedituoteCatalog = Boolean(this.$storage.getCookie('medituote-catalog'));
        this.rootCategory = categories.items;

        if (
            this.$store.state.auth.loggedIn &&
            this.$config.IS_HANSEL &&
            !isMedituoteCatalog &&
            this.rootCategory &&
            this.rootCategory[0] &&
            Array.isArray(this.rootCategory[0].children)
        ) {
            this.rootCategory[0].children.push({
                include_in_menu: true,
                children: [],
                uid: 'materials',
                name: this.$t('materials.title'),
                url_path: '/materiaalit',
            });
        }
        this.popupItem = this.$el;

        if (this.$store.state.auth.loggedIn) {
            await this.$store.dispatch('wishlist/getShoppingLists');
        }

        if (!this.$store.state.auth.loggedIn) {
            const notifications = await this.$strapi.getEntries({
                contentType: 'top-notifications',
            });

            if (notifications.data && notifications.data.length) {
                this.promoInfo = [...notifications.data.map((item) => item.attributes.label)];
            }
        }
    },
    fetchKey: 'nav',
    computed: {
        isHanselLogo() {
            if (this.$store.state.auth.customLogoUrl) {
                return false;
            }

            if (this.$config.IS_HANSEL) {
                const isMedituoteCatalog = Boolean(this.$storage.getCookie('medituote-catalog'));

                return !isMedituoteCatalog;
            } else {
                return false;
            }
        },
        showBlogLink() {
            if (this.$config.IS_HANSEL) {
                const isMedituoteCatalog = Boolean(this.$storage.getCookie('medituote-catalog'));

                return isMedituoteCatalog;
            } else {
                return true;
            }
        },
    },
    watch: {
        $route() {
            this.onClose();
        },
        '$store.state.auth.loggedIn'() {
            this.$fetch();
        },
        '$store.state.auth.user.email'() {
            this.$fetch();
        },
    },
    methods: {
        subCategoryChunks(category, subFlyout) {
            const subCategories = [];
            const potentialChildren = category.children.filter((child) => subFlyout === child.uid);
            if (Array.isArray(potentialChildren) && potentialChildren.length === 1) {
                const [children] = potentialChildren;
                subCategories.push(...children);
            }

            subCategories.push(...category.children);

            subCategories.forEach((subCategory) => {
                subCategory.linkToMain = false;
            });

            const mainCategory = { ...category };
            mainCategory.children = [];
            mainCategory.name = this.$t('navigation.category.lookall');
            mainCategory.linkToMain = true;

            subCategories.unshift(mainCategory);

            const columns = 3;
            const chunkSize = Math.ceil(subCategories.length / columns);
            const chunks = [];

            for (let i = 0; i < subCategories.length; i += chunkSize) {
                chunks.push(subCategories.slice(i, i + chunkSize));
            }

            return chunks;
        },
        openSubCategory(uid) {
            this.openedSubCategories.push(uid);
        },
        closeSubCategory(uid) {
            const index = this.openedSubCategories.indexOf(uid);
            if (index !== -1) {
                this.openedSubCategories.splice(index, 1);
            }
        },
        openMobileRoot() {
            this.currentMobileLevel = 'root';
            this.currentMobileCategory = 0;
            this.currentMobileSubCategory = 0;
        },
        openMobileCategory(uid) {
            this.currentMobileCategory = uid;
            this.currentMobileLevel = 'category';
        },
        openMobileSubCategory(uid) {
            this.currentMobileSubCategory = uid;
            this.currentMobileLevel = 'subCategory';
        },
        isOpened(uid) {
            return this.openedSubCategories.includes(uid);
        },
        unpin() {
            try {
                const max = this.$refs.header.scrollHeight;
                const headroom = this.$refs.headroom.$el.scrollHeight;
                this.offset = max - headroom;
            } catch (e) {
                console.error('sticky offset error');
            }
        },
        onClose() {
            this.flyout = 0;
            this.mobileMenuOpen = false;
            this.subFlyout = 0;
        },
        onClick(uid) {
            this.subFlyout = 0;
            if (this.flyout === uid) {
                this.flyout = 0;
            } else {
                this.flyout = uid;
            }
        },
        async logout() {
            await this.$auth.unsetUser();
            await this.$router.push('/');
        },
    },
};
