import getCompanyShoppingListsCount from '@/queries/getCompanyShoppingListsCount.graphql';
import getCustomerShoppingListsCount from '@/queries/getCustomerShoppingListsCount.graphql';

export const state = () => ({
    companyWishlistsCount: null,
    customerWishlistsCount: null,
    loading: false,
});
export const mutations = {
    setCompanyWishlistsCount(state, value) {
        state.companyWishlistsCount = value;
    },
    setCustomerWishlistsCount(state, value) {
        state.customerWishlistsCount = value;
    },
    setLoading(state, value) {
        state.loading = value;
    },
};
export const actions = {
    setLoading({ commit }, value) {
        commit('setLoading', value);
    },
    async getShoppingLists({ commit }) {
        try {
            commit('setLoading', true);
            const client = this.app.apolloProvider.defaultClient;
            const {
                data: { company },
            } = await client.query({
                query: getCompanyShoppingListsCount,
                fetchPolicy: 'no-cache',
            });

            const {
                data: { customer },
            } = await client.query({
                query: getCustomerShoppingListsCount,
                fetchPolicy: 'no-cache',
            });

            commit('setCompanyWishlistsCount', company.shoppingLists.total_count);
            commit('setCustomerWishlistsCount', customer.shoppingLists.total_count);
            commit('setLoading', false);

            return company.shoppingLists.total_count;
        } catch (e) {
            commit('setCompanyWishlistsCount', null);
            commit('setCustomerWishlistsCount', null);
            commit('setLoading', false);
            throw new Error(e.message);
        }
    },
    emptyWishlist({ commit }) {
        commit('setCompanyWishlistsCount', null);
        commit('setCustomerWishlistsCount', null);

        return {};
    },
};
