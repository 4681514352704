import { gql } from 'graphql-tag';
import CartListFragment from '@/queries/fragments/cartItems.gql.js';

export default gql`
    mutation setShippingMethodsOnCart($cartId: String!, $shippingMethods: [ShippingMethodInput]!) {
        setShippingMethodsOnCart(input: { cart_id: $cartId, shipping_methods: $shippingMethods }) {
            cart {
                id
                ...CartListFragment
            }
        }
    }

    ${CartListFragment}
`;
