
export default {
    props: {
        model: {
            type: String,
            required: true,
        },
    },
    mounted() {
        const linkElements = this.$el.getElementsByTagName('a');
        for (let i = 0; i < linkElements.length; i++) {
            const linkDestination = linkElements[i].href;
            const isFull = linkDestination && linkDestination.startsWith('http');
            const isExternal = isFull ? !linkDestination.startsWith(this.$config.HOSTNAME) : false;
            if (isExternal) {
                linkElements[i].setAttribute('target', '_blank');
            }
        }
    },
};
