
export default {
    props: {
        theme: {
            type: String,
            required: false,
            default: 'default',
            validator(value) {
                return ['default', 'info', 'success', 'error', 'warning'].includes(value);
            },
        },
        iconCode: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {};
    },
    computed: {
        colors() {
            if (this.theme === 'info') {
                return 'bg-primary-light border-primary';
            }
            if (this.theme === 'success') {
                return 'bg-success-light border-success';
            }
            if (this.theme === 'error') {
                return 'bg-error-light border-error';
            }
            if (this.theme === 'warning') {
                return 'bg-warning-light border-warning';
            }

            return 'bg-[#E1EAEB] border-[#2DB1C8]';
        },

        textColors() {
            if (this.theme === 'info') {
                return 'text-primary mr-2';
            }
            if (this.theme === 'success') {
                return 'text-success mr-2';
            }
            if (this.theme === 'error') {
                return 'text-error mr-2';
            }
            if (this.theme === 'warning') {
                return 'text-warning mr-2';
            }

            return 'mr-2';
        },
    },
};
