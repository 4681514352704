
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            cartOpen: 'cart/open',
        }),
        amount() {
            try {
                if (this.$store.state.cart.cart.total_quantity) {
                    return this.$store.state.cart.cart.total_quantity;
                }
                if (this.$storage.getCookie('cart_qty')) {
                    return this.$storage.getCookie('cart_qty');
                }

                return '0';
            } catch {
                return '0';
            }
        },
        userInitials() {
            if (this.$store.state.auth?.user?.firstname && this.$store.state.auth?.user?.lastname) {
                return `${this.$store.state.auth.user.firstname[0]}${this.$store.state.auth.user.lastname[0]}`;
            }

            return null;
        },
        wishlistSize() {
            const totalWishlistsCount = (this.$store.state.wishlist.companyWishlistsCount || 0) + (this.$store.state.wishlist.customerWishlistsCount || 0);

            return totalWishlistsCount >= 1 ? totalWishlistsCount : null;
        },
        showStoreSwitcher() {
            return (
                this.$config.IS_HANSEL &&
                this.$store.state.auth.loggedIn &&
                this.$store.state.auth.user &&
                this.$store.state.auth.user.is_allowed_to_view_store_switch
            );
        },
    },
};
