
export default {
    props: {
        packages: {
            type: Array,
            required: true,
        },
        qty: {
            type: Number,
            required: true,
        },
    },
    computed: {
        quantityString() {
            if (this.packages.length !== 2) {
                return '';
            }

            if (this.qty === 0) {
                return '';
            }

            const smallSize = this.packages[0].size;
            const smallLabel = this.packages[0].label.split(' ')[0];
            const largeSize = this.packages[1].size;
            const largeLabel = this.packages[1].label.split(' ')[0];

            if (this.qty < largeSize) {
                return '';
            }

            if (this.qty % largeSize === 0) {
                return `= ${this.qty / largeSize} ${largeLabel}`;
            } else {
                return `= ${Math.floor(this.qty / largeSize)} ${largeLabel} ${this.$t('ui.and')} ${Math.floor(
                    (this.qty % largeSize) / smallSize,
                )} ${smallLabel}`;
            }
        },
    },
};
