import { gql } from 'graphql-tag';
import CartListFragment from '@/queries/fragments/cartItems.gql.js';

export default gql`
    query getCart($id: String!) {
        cart(cart_id: $id) {
            id
            ...CartListFragment
        }
    }
    ${CartListFragment}
`;
