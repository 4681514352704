import { gql } from 'graphql-tag';
import CartListFragment from '@/queries/fragments/cartItems.gql.js';

export default gql`
    mutation setShippingAddressOnCart($cartId: String!, $address: [ShippingAddressInput]!) {
        setShippingAddressesOnCart(input: { cart_id: $cartId, shipping_addresses: $address }) {
            cart {
                id
                ...CartListFragment
            }
        }
    }
    ${CartListFragment}
`;
