import { gql } from 'graphql-tag';

export default gql`
    fragment CartListFragment on Cart {
        id
        total_quantity
        email
        estimated_delivery_date {
            delivery_earliest_at
            delivery_latest_at
            delivery_earliest_in_days
            delivery_latest_in_days
        }
        applied_coupons {
            code
        }
        prices {
            grand_total {
                value
                currency
            }
            applied_taxes {
                label
                amount {
                    value
                    currency
                }
            }
            discounts {
                label
                amount {
                    value
                    currency
                }
            }
            subtotal_including_tax {
                currency
                value
            }
            subtotal_excluding_tax {
                currency
                value
            }
            subtotal_with_discount_excluding_tax {
                currency
                value
            }
        }
        billing_address {
            firstname
            lastname
            country {
                code
                label
            }
            company
            postcode
            street
            city
            telephone
        }
        shipping_addresses {
            selected_shipping_method {
                carrier_code
                method_code
                price_excl_tax {
                    value
                    currency
                }
                price_incl_tax {
                    value
                    currency
                }
            }
            firstname
            lastname
            country {
                code
                label
            }
            company
            postcode
            street
            city
            telephone
            unit
            e_billing_address
            e_billing_operator
            e_billing_reference
            available_shipping_methods {
                method_title
                carrier_code
                carrier_title
                method_code
                amount {
                    currency
                    value
                }
            }
        }
        available_payment_methods {
            code
            title
        }
        selected_payment_method {
            code
            title
            purchase_order_number
        }
        items {
            uid
            ... on GroupedCartItem {
                parent {
                    sku
                    url_key
                    thumbnail {
                        url
                        label
                    }
                }
            }
            product {
                uid
                sku
                name
                url_key
                thumbnail {
                    url
                    label
                }
                price_tiers {
                    quantity
                    final_price {
                        value
                        currency
                    }
                }
                price_range {
                    minimum_price {
                        regular_price {
                            value
                            currency
                        }
                        final_price {
                            value
                            currency
                        }
                    }
                }
                packaging {
                    packaging_unit_package
                    packaging_unit_individual
                    packaging_amount_package
                    packaging_amount_individual
                    packaging_unit_abbreviation_individual
                    packaging_unit_abbreviation_package
                }
                related_products {
                    sku
                }
                custom_attributes {
                    selected_attribute_options {
                        attribute_option {
                            is_default
                            label
                        }
                    }
                    attribute_metadata {
                        code
                        label
                    }
                    entered_attribute_value {
                        value
                    }
                }
                stock_status
                is_external
                new_from_date
                new_to_date
                ... on GroupedProduct {
                    items {
                        product {
                            price_range {
                                minimum_price {
                                    regular_price {
                                        value
                                    }
                                    final_price {
                                        value
                                    }
                                }
                            }
                            url_key
                            uid
                            thumbnail {
                                url
                            }
                            is_external
                            new_from_date
                            new_to_date
                        }
                    }
                }
            }
            prices {
                price_including_tax {
                    value
                    currency
                }
                price {
                    value
                    currency
                }
                row_total_including_tax {
                    currency
                    value
                }
                discounts {
                    amount {
                        value
                        currency
                    }
                }
                price {
                    currency
                    value
                }
            }
            quantity
            ... on ConfigurableCartItem {
                configured_variant {
                    thumbnail {
                        url
                    }
                }
                product {
                    url_key
                    thumbnail {
                        url
                    }
                }
                configurable_options {
                    configurable_product_option_uid
                    option_label
                    configurable_product_option_value_uid
                    value_label
                }
            }
        }
    }
`;
