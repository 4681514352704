
export default {
    props: {
        type: {
            type: String,
            required: true,
            validator(value) {
                return ['popular', 'new', 'sale', 'external'].includes(value);
            },
        },
        withBorder: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        background() {
            if (this.type === 'popular') return 'bg-extra';
            if (this.type === 'new') return 'bg-success-light';
            if (this.type === 'sale') return 'bg-primary-midlight';
            if (this.type === 'external') return 'bg-warning-light';

            return '';
        },
        outline() {
            if (this.type === 'popular') return 'outline-primary';
            if (this.type === 'new') return 'outline-success';
            if (this.type === 'sale') return 'outline-primary';
            if (this.type === 'external') return 'outline-primary';

            return '';
        },
        border() {
            if (this.type === 'popular') return 'border border-primary';
            if (this.type === 'new') return 'border border-success';
            if (this.type === 'sale') return 'border border-primary';
            if (this.type === 'external') return 'border border-primary';

            return '';
        },
        computedClasses() {
            const classes = [this.background, this.outline];

            if (this.withBorder) {
                classes.push(this.border);
            }

            return classes.join(' ');
        },
    },
};
