export default function ({ $axios, app, store, $auth, $i18n, $config, $storage }) {
    const MEDITUOTE_STORE_VIEW_CODE = 'default';
    const HANSEL_STORE_VIEW_CODE = 'hansel_hansel_fi';
    const HANSEL_MEDITUOTE_CATALOG_STORE_VIEW_CODE = 'hansel_medituote_fi';

    // Fix for running in Google Cloud Run
    $axios.defaults.baseURL = `${app.$config.HOSTNAME}/api`;
    $axios.onRequest((config) => {
        let group = '0';
        try {
            if (store.state.auth.user) {
                group = String(store.state.auth.user.company.company_id);
            }
        } catch (e) {
            group = '0';
        }

        const isMedituoteCatalog = Boolean($storage.getCookie('medituote-catalog'));

        if (config.headers.common) {
            config.headers.common.Group = group || '0';
            config.headers.common['Apollo-Token'] = app.$storage.getCookie('apollo-token') || '';
            config.headers.common.Store = $config.IS_HANSEL
                ? isMedituoteCatalog
                    ? HANSEL_MEDITUOTE_CATALOG_STORE_VIEW_CODE
                    : HANSEL_STORE_VIEW_CODE
                : MEDITUOTE_STORE_VIEW_CODE;
        }
    });
}
