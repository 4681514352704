
export default {
    data() {
        return {
            switchHansel: this.$t('hansel.switch.tooltip.tohansel'),
            switchMedituote: this.$t('hansel.switch.tooltip.tomedituote'),
        };
    },
    computed: {
        isMedituoteCatalog() {
            return Boolean(this.$storage.getCookie('medituote-catalog'));
        },
    },
    methods: {
        async openHanselDialog() {
            await this.$store.dispatch('switcher/open');
            await this.$store.dispatch('switcher/setHansel');
        },
        async openMedituoteDialog() {
            await this.$store.dispatch('switcher/open');
            await this.$store.dispatch('switcher/setMedituote');
        },
    },
};
