
export default {
    name: 'QuantitySelector',
    props: {
        /**
         * Default value
         */
        value: {
            type: [Number, String],
            required: true,
            default: 1,
        },
        /**
         * Minimum value
         */
        min: {
            type: Number,
            required: false,
            default: 1,
        },
        /**
         * Maximum value
         */
        max: {
            type: Number,
            required: false,
            default: 9999,
        },
        step: {
            type: Number,
            required: false,
            default: 1,
        },
    },
    data() {
        const originalVal = this.value;

        return {
            originalVal,
        };
    },
    computed: {
        inputVal: {
            get() {
                return parseInt(this.value);
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    methods: {
        setQty(dir) {
            let qty = this.inputVal;
            if (dir) {
                qty += this.step;
            } else {
                qty -= this.step;
            }
            if (qty < this.min) {
                qty = this.min;
            }
            if (this.max) {
                if (qty > this.max) {
                    qty = this.max;
                }
            }

            this.inputVal = qty;
            this.updateQty();
        },
        // Check that the keyboard inputs are valid and stay within bounds
        validateInput() {
            const parsed = parseInt(this.inputVal);
            if (isNaN(parsed)) {
                this.inputVal = this.min;
            } else if (parsed < this.min) {
                this.inputVal = this.min;
            } else if (this.max && parsed > this.max) {
                this.inputVal = this.max;
            }
            this.updateQty();
        },
        updateQty() {
            this.$emit('update', this.inputVal);
        },
    },
};
