export default function ({ store, redirect, app }) {
    // If the user is punchout user, the user will be redirected to main page
    const isPunchoutCookie = Boolean(app.$storage.getCookie('punchout_login'));
    const isPunchoutUser = store.state.auth.user && store.state.auth.user.punchout_customer;
    const isPunchout = isPunchoutCookie || isPunchoutUser;

    if (isPunchout) {
        return redirect('/');
    }
}
