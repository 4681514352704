
export default {
    data() {
        const accountLinks = [
            {
                title: 'Kirjaudu',
                url: '/account/login',
            },
        ];
        if (!this.$store.state.auth.companyRegistrationDisabled) {
            accountLinks.push({
                title: 'Rekisteröidy',
                url: '/account/register',
            });
        }
        accountLinks.push(
            {
                title: 'Myynti- ja toimitusehdot',
                url: '/static/toimitusehdot',
            },
            {
                title: 'Rekisteri- ja tietosuojaseloste',
                url: '/static/tietosuojaseloste',
            },
        );

        return {
            banner: null,
            content: null,
            preFooterLinks: [
                {
                    title: 'Asiakaspalvelu',
                    url: '/yrityksesta/yhteystiedot',
                    direct: true,
                },
                {
                    title: 'Tilaus',
                    url: '/account/orders',
                    direct: false,
                },
                {
                    title: 'Palautukset',
                    url: '/yrityksesta/yhteystiedot',
                    direct: true,
                },
            ],
            categoryLinks: [],
            accountLinks,
            aboutUsFooterLinks: [
                {
                    title: 'Yrityksestä',
                    url: '/yrityksesta',
                },
                {
                    title: 'Sopimusasiakkuus',
                    url: '/yrityksesta/sopimusasiakkuus',
                },
                {
                    title: 'Vastuullisuus',
                    url: '/yrityksesta/vastuullisuus',
                },
                {
                    title: 'Yhteystiedot',
                    url: '/yrityksesta/yhteystiedot',
                },
            ],
        };
    },
    async fetch() {
        const bannerItems = await this.$strapi.getEntries({
            contentType: 'footer-banners',
            populate: ['image'],
        });

        if (bannerItems.data) {
            [this.banner] = bannerItems.data;
        }

        const footerItems = await this.$strapi.getEntries({
            contentType: 'footer-contents',
        });

        if (footerItems.data && footerItems.data.length) {
            this.content = footerItems.data[0].attributes;
        }

        const categoryLinks = await this.$strapi.getEntries({
            contentType: 'footer-links',
        });

        if (categoryLinks.data && categoryLinks.data.length) {
            this.categoryLinks = categoryLinks.data;
        }
    },
    computed: {
        showCategoryLinks() {
            if (this.$config.IS_MEDITUOTE) {
                return true;
            }

            return this.$store.state.auth.loggedIn;
        },
    },
    fetchOnServer: false,
};
