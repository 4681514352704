
export default {
    props: {
        product: {
            type: Object,
            required: true,
        },
        showOnlySale: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        showBadge() {
            return Boolean(this.badgeType);
        },
        badgeType() {
            if (this.product.items && this.product.items.length) {
                for (let i = 0; i < this.product.items.length; i++) {
                    if (this.checkIfDiscounted(this.product.items[i].product) && !this.$store.state.auth.hideSpecialPriceBadge) {
                        return 'sale';
                    }
                }
            } else if (this.checkIfDiscounted(this.product) && !this.$store.state.auth.hideSpecialPriceBadge) {
                return 'sale';
            }

            if (this.showOnlySale) {
                return null;
            }

            if (this.checkIfNew(this.product)) {
                return 'new';
            }

            if (this.product.items && this.product.items.length) {
                for (let i = 0; i < this.product.items.length; i++) {
                    if (this.checkIfNew(this.product.items[i].product)) {
                        return 'new';
                    }
                }
            }

            return null;
        },
        badgeContent() {
            if (this.badgeType === 'new') {
                return this.$t('catalog.badge.new');
            }

            if (this.badgeType === 'sale') {
                return this.$t('catalog.badge.sale');
            }

            return '';
        },
        showFullPrice() {
            if (!this.$store.state.auth.loggedIn || !this.$store.state.auth.user) {
                return true;
            }

            return this.$store.state.auth.user.is_allowed_to_view_regular_price;
        },
    },
    methods: {
        checkIfNew(product) {
            if (product.new_from_date || product.new_to_date) {
                const now = new Date();

                if (product.new_from_date && product.new_to_date) {
                    const fromDate = new Date(product.new_from_date);
                    const toDate = new Date(product.new_to_date);

                    if (fromDate <= now && now <= toDate) {
                        return true;
                    }

                    return false;
                }

                if (!product.new_from_date && product.new_to_date) {
                    const toDate = new Date(product.new_to_date);

                    if (now <= toDate) {
                        return true;
                    }

                    return false;
                }

                if (product.new_from_date && !product.new_to_date) {
                    const fromDate = new Date(product.new_from_date);

                    if (fromDate <= now) {
                        return true;
                    }

                    return false;
                }

                return false;
            }

            return false;
        },
        checkIfDiscounted(product) {
            if (this.showFullPrice) {
                return product.price_range.minimum_price.final_price.value !== product.price_range.minimum_price.regular_price.value;
            } else {
                return false;
            }
        },
    },
};
