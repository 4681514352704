import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _092e9b48 = () => interopDefault(import('../src/pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _89bcfc30 = () => interopDefault(import('../src/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _b5d1fd4a = () => interopDefault(import('../src/pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _16ff4c01 = () => interopDefault(import('../src/pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _7e1d9b60 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _413ed023 = () => interopDefault(import('../src/pages/styleguide.vue' /* webpackChunkName: "pages/styleguide" */))
const _1d95d4fc = () => interopDefault(import('../src/pages/about/index.vue' /* webpackChunkName: "" */))
const _79d3b866 = () => interopDefault(import('../src/pages/about/contacts.vue' /* webpackChunkName: "pages/about/contacts" */))
const _27f4c8eb = () => interopDefault(import('../src/pages/about/contract-customership.vue' /* webpackChunkName: "pages/about/contract-customership" */))
const _b38befb6 = () => interopDefault(import('../src/pages/about/forms.vue' /* webpackChunkName: "pages/about/forms" */))
const _bd542af0 = () => interopDefault(import('../src/pages/about/responsibility.vue' /* webpackChunkName: "pages/about/responsibility" */))
const _2c89238d = () => interopDefault(import('../src/pages/account/address/index.vue' /* webpackChunkName: "pages/account/address/index" */))
const _fe172654 = () => interopDefault(import('../src/pages/account/company/index.vue' /* webpackChunkName: "pages/account/company/index" */))
const _c210461e = () => interopDefault(import('../src/pages/account/createpassword.vue' /* webpackChunkName: "pages/account/createpassword" */))
const _f66db602 = () => interopDefault(import('../src/pages/account/login.vue' /* webpackChunkName: "pages/account/login" */))
const _3e108682 = () => interopDefault(import('../src/pages/account/orders/index.vue' /* webpackChunkName: "pages/account/orders/index" */))
const _29b95f7d = () => interopDefault(import('../src/pages/account/register.vue' /* webpackChunkName: "pages/account/register" */))
const _22cc5480 = () => interopDefault(import('../src/pages/account/requestpassword.vue' /* webpackChunkName: "pages/account/requestpassword" */))
const _2e6a9218 = () => interopDefault(import('../src/pages/account/reviews/index.vue' /* webpackChunkName: "pages/account/reviews/index" */))
const _5c017068 = () => interopDefault(import('../src/pages/account/user/index.vue' /* webpackChunkName: "pages/account/user/index" */))
const _0e7818e2 = () => interopDefault(import('../src/pages/account/wishlist/index.vue' /* webpackChunkName: "pages/account/wishlist/index" */))
const _10b74e1c = () => interopDefault(import('../src/pages/checkout/success.vue' /* webpackChunkName: "pages/checkout/success" */))
const _8abd7c4e = () => interopDefault(import('../src/pages/about/forms.vue' /* webpackChunkName: "" */))
const _183a1a9f = () => interopDefault(import('../src/pages/about/contract-customership.vue' /* webpackChunkName: "" */))
const _a2bb4958 = () => interopDefault(import('../src/pages/about/responsibility.vue' /* webpackChunkName: "" */))
const _1b8a1d99 = () => interopDefault(import('../src/pages/about/contacts.vue' /* webpackChunkName: "" */))
const _0aea7a1b = () => interopDefault(import('../src/pages/account/address/new.vue' /* webpackChunkName: "pages/account/address/new" */))
const _0ee81301 = () => interopDefault(import('../src/pages/account/company/edituser.vue' /* webpackChunkName: "pages/account/company/edituser" */))
const _3b9929ef = () => interopDefault(import('../src/pages/account/company/newuser.vue' /* webpackChunkName: "pages/account/company/newuser" */))
const _5fd34325 = () => interopDefault(import('../src/pages/account/user/changepassword.vue' /* webpackChunkName: "pages/account/user/changepassword" */))
const _acb8e140 = () => interopDefault(import('../src/pages/account/wishlist/personal.vue' /* webpackChunkName: "pages/account/wishlist/personal" */))
const _1196b596 = () => interopDefault(import('../src/pages/account/address/_id.vue' /* webpackChunkName: "pages/account/address/_id" */))
const _9c9b7d2c = () => interopDefault(import('../src/pages/account/orders/_id.vue' /* webpackChunkName: "pages/account/orders/_id" */))
const _1eb3a0b5 = () => interopDefault(import('../src/pages/account/return/_id.vue' /* webpackChunkName: "pages/account/return/_id" */))
const _80203f52 = () => interopDefault(import('../src/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _092e9b48,
    name: "about___fi"
  }, {
    path: "/account",
    component: _89bcfc30,
    name: "account___fi"
  }, {
    path: "/cart",
    component: _b5d1fd4a,
    name: "cart___fi"
  }, {
    path: "/checkout",
    component: _16ff4c01,
    name: "checkout___fi"
  }, {
    path: "/search",
    component: _7e1d9b60,
    name: "search___fi"
  }, {
    path: "/styleguide",
    component: _413ed023,
    name: "styleguide___fi"
  }, {
    path: "/yrityksesta",
    component: _1d95d4fc,
    name: "about_index"
  }, {
    path: "/about/contacts",
    component: _79d3b866,
    name: "about-contacts___fi"
  }, {
    path: "/about/contract-customership",
    component: _27f4c8eb,
    name: "about-contract-customership___fi"
  }, {
    path: "/about/forms",
    component: _b38befb6,
    name: "about-forms___fi"
  }, {
    path: "/about/responsibility",
    component: _bd542af0,
    name: "about-responsibility___fi"
  }, {
    path: "/account/address",
    component: _2c89238d,
    name: "account-address___fi"
  }, {
    path: "/account/company",
    component: _fe172654,
    name: "account-company___fi"
  }, {
    path: "/account/createpassword",
    component: _c210461e,
    name: "account-createpassword___fi"
  }, {
    path: "/account/login",
    component: _f66db602,
    name: "account-login___fi"
  }, {
    path: "/account/orders",
    component: _3e108682,
    name: "account-orders___fi"
  }, {
    path: "/account/register",
    component: _29b95f7d,
    name: "account-register___fi"
  }, {
    path: "/account/requestpassword",
    component: _22cc5480,
    name: "account-requestpassword___fi"
  }, {
    path: "/account/reviews",
    component: _2e6a9218,
    name: "account-reviews___fi"
  }, {
    path: "/account/user",
    component: _5c017068,
    name: "account-user___fi"
  }, {
    path: "/account/wishlist",
    component: _0e7818e2,
    name: "account-wishlist___fi"
  }, {
    path: "/checkout/success",
    component: _10b74e1c,
    name: "checkout-success___fi"
  }, {
    path: "/yrityksesta/lomakkeet",
    component: _8abd7c4e,
    name: "about_forms"
  }, {
    path: "/yrityksesta/sopimusasiakkuus",
    component: _183a1a9f,
    name: "about_contract-customership"
  }, {
    path: "/yrityksesta/vastuullisuus",
    component: _a2bb4958,
    name: "about_responsibility"
  }, {
    path: "/yrityksesta/yhteystiedot",
    component: _1b8a1d99,
    name: "about_contacts"
  }, {
    path: "/account/address/new",
    component: _0aea7a1b,
    name: "account-address-new___fi"
  }, {
    path: "/account/company/edituser",
    component: _0ee81301,
    name: "account-company-edituser___fi"
  }, {
    path: "/account/company/newuser",
    component: _3b9929ef,
    name: "account-company-newuser___fi"
  }, {
    path: "/account/user/changepassword",
    component: _5fd34325,
    name: "account-user-changepassword___fi"
  }, {
    path: "/account/wishlist/personal",
    component: _acb8e140,
    name: "account-wishlist-personal___fi"
  }, {
    path: "/account/address/:id",
    component: _1196b596,
    name: "account-address-id___fi"
  }, {
    path: "/account/orders/:id",
    component: _9c9b7d2c,
    name: "account-orders-id___fi"
  }, {
    path: "/account/return/:id?",
    component: _1eb3a0b5,
    name: "account-return-id___fi"
  }, {
    path: "/*",
    component: _80203f52,
    name: "all___fi"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
