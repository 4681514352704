import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

import { createHttpLink } from 'apollo-link-http';
import { stripIgnoredCharacters } from 'graphql/utilities/stripIgnoredCharacters';
// eslint-disable-next-line import/no-absolute-path
import schema from '/fragmentTypes.json';

const MEDITUOTE_STORE_VIEW_CODE = 'default';
const HANSEL_STORE_VIEW_CODE = 'hansel_hansel_fi';
const HANSEL_MEDITUOTE_CATALOG_STORE_VIEW_CODE = 'hansel_medituote_fi';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: schema,
});

const customFetchToShrinkQuery = (uri, options) => {
    let url = uri;
    if (options.method === 'GET') {
        url = shrinkGETQuery(uri);
    }

    return fetch(url, options);
};

export function shrinkGETQuery(fullURL) {
    const url = new URL(fullURL);

    // Read from URL implicitly decodes the querystring
    const query = url.searchParams.get('query');
    if (!query) {
        return fullURL;
    }

    const strippedQuery = stripIgnoredCharacters(query);

    // URLSearchParams.set will use application/x-www-form-urlencoded encoding
    url.searchParams.set('query', strippedQuery);

    return url.toString();
}

export default function ({ $config, i18n, $storage }) {
    // const currentLocale = i18n.locales.find((item) => item.code === i18n.locale);
    const uri = $config.DEV ? 'http://localhost:1234/api' : $config.GRAPHQL_API;
    const isMedituoteCatalog = Boolean($storage.getCookie('medituote-catalog'));
    const link = createHttpLink({
        uri,
        headers: {
            Store: $config.IS_HANSEL ? (isMedituoteCatalog ? HANSEL_MEDITUOTE_CATALOG_STORE_VIEW_CODE : HANSEL_STORE_VIEW_CODE) : MEDITUOTE_STORE_VIEW_CODE,
        },
        useGETForQueries: true,
        fetchOptions: {
            mode: 'cors', // Cors Needed for external Cross origins, need to allow headers from server
        },
        credentials: 'omit', // must be omit to support application/json content type
        fetch: customFetchToShrinkQuery,
    });

    return {
        defaultHttpLink: false,
        link,
        cache: new InMemoryCache({ fragmentMatcher }),
    };
}
