
export default {
    data() {
        return {
            switchHansel: this.$t('hansel.switch.tooltip.tohansel'),
            switchMedituote: this.$t('hansel.switch.tooltip.tomedituote'),
            isLoading: false,
        };
    },
    methods: {
        async switchToHansel() {
            this.isLoading = true;
            await this.$storage.removeCookie('medituote-catalog');
            if (this.$route.path === '/' || this.$route.path.startsWith('/account/')) {
                this.$router.go(0);
            } else {
                await this.$router.replace('/');
                await this.$router.go(0);
            }
            await this.closeDialog();
            this.isLoading = false;
        },
        async switchToMedituote() {
            this.isLoading = true;
            await this.$storage.setCookie('medituote-catalog', 'true');
            if (this.$route.path === '/' || this.$route.path.startsWith('/account/')) {
                this.$router.go(0);
            } else {
                await this.$router.replace('/');
                await this.$router.go(0);
            }
            await this.closeDialog();
            this.isLoading = false;
        },
        async closeDialog() {
            await this.$store.dispatch('switcher/close');
            await this.$store.dispatch('switcher/unsetStoreView');
        },
    },
};
