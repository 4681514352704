import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=4a24f5a1&"
import script from "./Navigation.vue?vue&type=script&lang=js&"
export * from "./Navigation.vue?vue&type=script&lang=js&"
import style0 from "./Navigation.vue?vue&type=style&index=0&id=4a24f5a1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OpusIcon: require('/~/src/modules/opus/components/Icon.vue').default,LoginAsCustomer: require('/~/src/components/LoginAsCustomer.vue').default,Quicksearch: require('/~/src/modules/opus-ecom/components/Quicksearch.vue').default,Logo: require('/~/src/modules/opus-ecom/components/Logo.vue').default,LogoSmallMobile: require('/~/src/modules/opus-ecom/components/LogoSmallMobile.vue').default,AccountNavigation: require('/~/src/modules/opus-ecom/components/AccountNavigation.vue').default,CategoryPromotion: require('/~/src/components/CategoryPromotion.vue').default,Cart: require('/~/src/modules/opus-ecom/components/Cart.vue').default})
