import { gql } from 'graphql-tag';
import CartListFragment from '@/queries/fragments/cartItems.gql.js';

export default gql`
    mutation setBillingAddressOnCart($cartId: String!, $address: BillingAddressInput!) {
        setBillingAddressOnCart(input: { cart_id: $cartId, billing_address: $address }) {
            cart {
                id
                ...CartListFragment
            }
        }
    }

    ${CartListFragment}
`;
