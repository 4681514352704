
export default {
    props: {
        to: {
            type: [String, Object],
            required: true,
        },
        openNewTab: {
            type: Boolean,
            required: false,
            default: false,
        },
        flush: {
            type: Boolean,
            required: false,
            default: false,
        },
        alt: {
            type: String,
            required: true,
        },
        icon: {
            type: Boolean,
            required: false,
            default: true,
        },
        reverseIcon: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        isExternal() {
            return typeof this.to === 'string' && this.to.startsWith('http');
        },
    },
};
