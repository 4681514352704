
export default {
    props: {
        open: {
            type: Boolean,
            required: true,
        },
        wide: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {};
    },
    methods: {
        hide(event) {
            if (event.target.dataset.closetrigger) {
                this.$emit('close');
            }

            // Close icon
            if (event.target.tagName === 'path' && event.target.parentNode.tagName === 'g' && event.target.parentNode.parentNode.dataset.closetrigger) {
                this.$emit('close');
            }
        },
    },
};
