
export default {
    computed: {
        totals() {
            return this.$store.state.cart.cart.prices;
        },
        shipping() {
            try {
                const selected = this.$store.state.cart.cart.shipping_addresses[0].selected_shipping_method;

                return {
                    value: selected.price_excl_tax.value,
                    currency: selected.price_excl_tax.currency,
                };
            } catch (e) {
                return false;
            }
        },
        grandTotalExcludingTax() {
            const shippingExcludingTax = this.shipping ? this.shipping.value : 0;
            const subtotalExcludingTax = this.totals.subtotal_with_discount_excluding_tax.value;

            return shippingExcludingTax + subtotalExcludingTax;
        },
        taxes() {
            return this.totals.applied_taxes.map((tax) => tax.amount.value).reduce((taxA, taxB) => taxA + taxB, 0);
        },
    },
};
