import config from '#tailwind-config';

const { screens } = config.theme;

const breakpoints = {
    screens,
    isScreenMinWidth(width) {
        return this.requireWindow(() => {
            const widthInt = parseInt(width);

            return window.innerWidth >= widthInt;
        });
    },
    isScreenMaxWidth(width) {
        return this.requireWindow(() => {
            const widthInt = parseInt(width);

            return window.innerWidth <= widthInt;
        });
    },
    requireWindow(cb) {
        if (window) {
            return cb();
        }

        return false;
    },
};

export default function ({ $config }, inject) {
    inject('bps', breakpoints);

    return breakpoints;
}
