const notificationDuration = 10000;
export default (context, inject) => {
    const closeAction = {
        text: '\u2715',
        class: ['!text-text-dark', '!p-0', '!ml-3', 'scale-150'],
        onClick: (e, toastObject) => {
            toastObject.goAway(0);
        },
    };
    const { $toast } = context;
    const notify = {
        success(
            message,
            opt = {
                duration: notificationDuration,
                action: closeAction,
            },
        ) {
            $toast.success(message, { ...opt });
        },
        error(
            message,
            opt = {
                duration: notificationDuration,
                action: closeAction,
            },
        ) {
            $toast.error(message, { ...opt });
        },
        info(
            message,
            opt = {
                duration: notificationDuration,
                action: closeAction,
            },
        ) {
            $toast.info(message, { ...opt });
        },
        clear() {
            $toast.clear();
        },
        show(
            message,
            opt = {
                duration: notificationDuration,
                action: closeAction,
            },
        ) {
            $toast.show(message, { ...opt });
        },
    };
    inject('notify', notify);
    context.$notify = notify;
};
