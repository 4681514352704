export const state = () => ({
    nextStoreView: null,
    opened: false,
});
export const mutations = {
    setStoreView(state, value) {
        state.nextStoreView = value;
    },
    setOpened(state, value) {
        state.opened = value;
    },
};
export const actions = {
    setHansel({ commit }) {
        commit('setStoreView', 'hansel');
    },
    setMedituote({ commit }) {
        commit('setStoreView', 'medituote');
    },
    unsetStoreView({ commit }) {
        commit('setStoreView', null);
    },
    open({ commit }) {
        commit('setOpened', true);
    },
    close({ commit }) {
        commit('setOpened', false);
    },
};
