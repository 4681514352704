
export default {
    name: 'OpusButton',
    props: {
        /**
         * Button style
         */
        theme: {
            type: String,
            required: false,
            default: 'primary',
            validator(value) {
                return ['primary', 'secondary', 'tertiary', 'outline', 'gradient', 'success', 'danger', 'flush'].includes(value);
            },
        },
        /**
         * Text to show. If no label is given, default slot is used.
         */
        label: {
            type: String,
            required: false,
            default: '',
        },
        /**
         * https://nuxtjs.org/docs/features/nuxt-components/#the-nuxtlink-component
         */
        to: {
            type: [String, Object],
            required: false,
            default: undefined,
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
        iconCode: {
            type: String,
            required: false,
            default: '',
        },
        iconRight: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        tag() {
            if (this.$attrs.href) {
                return 'a';
            }

            return this.to ? 'nuxt-link' : 'button';
        },
    },
    render(h) {
        const data = {
            inheritAttrs: true,
            class: {
                'inline-flex justify-center items-center text-center transition-colors font-bold disabled:opacity-50 disabled:cursor-not-allowed':
                    this.theme !== 'flush',
                'py-3 px-5 lg:py-2 lg:px-4 text-lg lg:text-base rounded-md': !this.small && this.theme !== 'flush',
                'py-3 px-5 lg:px-4 lg:py-1 text-sm md:text-xs rounded-full uppercase': this.small && this.theme !== 'flush',
                'text-text-white bg-primary hover:bg-primary-middark focus:outline-accent focus:outline': this.theme === 'primary',
                'text-primary bg-text-white hover:bg-primary-light active:bg-primary-midlight border-2 border-primary focus:outline-accent focus:outline':
                    this.theme === 'secondary',
                'text-gray-900 bg-gray-50 hover:bg-gray-200 active:bg-gray-400 focus:bg-gray-50 border border-gray-500 focus:border-turqoise':
                    this.theme === 'tertiary',
                'bg-white text:primary border-primary hover:bg-secondary': this.theme === 'outline',
                'bg-gradient-to-r from-red-500 text-white': this.theme === 'gradient',
                'bg-success text-white': this.theme === 'success',
            },
            on: {
                click: (event) => this.$emit('click', event),
            },
            props: {
                to: this.to,
            },
        };

        return h(
            this.tag,
            data,
            this.label || this.iconCode
                ? [
                      h('span', {}, this.label),
                      h('OpusIcon', {
                          class: {
                              'h-5 w-5 ': true,
                              'ml-4 order-last': this.iconRight && (this.$slots.default || this.label),
                              'mr-4 order-first': !this.iconRight && (this.$slots.default || this.label),
                          },
                          props: {
                              code: this.iconCode,
                          },
                      }),
                  ]
                : this.$slots.default,
        );
    },
};
