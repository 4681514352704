
export default {
    props: {
        color: {
            type: [String, null],
            required: false,
            default: null,
        },
    },
};
