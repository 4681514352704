
export default {
    props: {
        color: {
            type: [String, null],
            required: false,
            default: null,
        },
    },
    computed: {
        isCustomLogo() {
            return Boolean(this.logoUrl);
        },
        logoUrl() {
            if (this.$store.state.auth.customLogoUrl) {
                return this.$store.state.auth.customLogoUrl;
            }

            if (this.$config.IS_HANSEL) {
                const isMedituoteCatalog = Boolean(this.$storage.getCookie('medituote-catalog'));

                return isMedituoteCatalog ? null : '/hansel-logo.svg';
            } else {
                return null;
            }
        },
    },
};
