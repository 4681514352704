import { gql } from 'graphql-tag';

export default gql`
    mutation addProduct($cartId: String!, $items: [CartItemInput!]!) {
        addProductsToCart(cartId: $cartId, cartItems: $items) {
            cart {
                id
                total_quantity
            }
            user_errors {
                code
                message
            }
        }
    }
`;
