import { gql } from 'graphql-tag';

export default gql`
    query getStoreConfig {
        storeConfig {
            free_shipping_subtotal
            free_shipping_enabled
            only_x_left_warning
            hide_special_price_badge
            is_company_registration_disabled
            catalog_search_min_query_length
            catalog_search_max_query_length
        }
    }
`;
