export const state = () => ({
    loggedIn: false,
    user: {},
    freeShippingSubtotal: null,
    freeShippingEnabled: false,
    hideSpecialPriceBadge: false,
    stockWarning: 20,
    companyRegistrationDisabled: false,
    categorySearchMinLength: 3,
    categorySearchMaxLength: 100,
    adminLogin: false,
    punchoutLogin: false,
    customLogoUrl: null,
});
export const mutations = {
    setLogged(state, value) {
        state.loggedIn = value;
    },
    setUser(state, value) {
        state.user = value;
        if (Object.keys(value).length === 0) {
            state.adminLogin = false;
            state.punchoutLogin = false;
            state.customLogoUrl = null;
        }
    },
    setStoreConfig(state, value) {
        state.stockWarning = value.only_x_left_warning;
        state.hideSpecialPriceBadge = Boolean(value.hide_special_price_badge);
        state.companyRegistrationDisabled = Boolean(value.is_company_registration_disabled);
        state.categorySearchMinLength = state.catalog_search_min_query_length ? Number(state.catalog_search_min_query_length) : 3;
        state.categorySearchMaxLength = state.catalog_search_max_query_length ? Number(state.catalog_search_max_query_length) : 100;
    },
    setFreeShippingLimit(state, value) {
        state.freeShippingSubtotal = value;
    },
    setFreeShippingEnabled(state, value) {
        state.freeShippingEnabled = value;
    },
    setAdminLogin(state, value) {
        state.adminLogin = value;
    },
    setPunchoutLogin(state, value) {
        state.punchoutLogin = value;
    },
    setCustomLogoUrl(state, value) {
        state.customLogoUrl = value;
    },
};
export const actions = {};

export const getters = {
    user: (state) => state.user,
    loggedIn: (state) => state.loggedIn,
};
