import { gql } from 'graphql-tag';
import CartListFragment from '@/queries/fragments/cartItems.gql.js';

export default gql`
    mutation setGuestEmailOnCart($cartId: String!, $email: String!) {
        setGuestEmailOnCart(input: { cart_id: $cartId, email: $email }) {
            cart {
                email
                ...CartListFragment
            }
        }
    }
    ${CartListFragment}
`;
