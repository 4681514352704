export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"fi","file":"fi.js","name":"Finnish","store":"medituote_finland_fi"}],
  defaultLocale: "fi",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "locale/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"fi","file":"fi.js","name":"Finnish","store":"medituote_finland_fi"}],
  localeCodes: ["fi"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "f",
  3: "i",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "s",
  14: "r",
  15: "c",
  16: "/",
  17: "l",
  18: "o",
  19: "c",
  20: "a",
  21: "l",
  22: "e",
  23: "/",
  24: "f",
  25: "i",
  26: ".",
  27: "j",
  28: "s",
  29: "\"",
  30: "}",
}

export const localeMessages = {
  'fi.js': () => import('../../src/locale/fi.js' /* webpackChunkName: "lang-fi.js" */),
}
