
export default {
    props: {
        showNotification: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        awayFromFreeShipping() {
            return this.$store.state.auth.freeShippingSubtotal - this.$store.state.cart.cart.prices.subtotal_excluding_tax.value;
        },
        freeShippingEnabled() {
            return this.$store.state.auth.freeShippingEnabled;
        },
    },
    methods: {},
};
