import { gql } from 'graphql-tag';
import CartListFragment from '@/queries/fragments/cartItems.gql.js';

export default gql`
    mutation removeCouponOnCart($cartId: String!) {
        removeCouponFromCart(input: { cart_id: $cartId }) {
            cart {
                id
                ...CartListFragment
            }
        }
    }

    ${CartListFragment}
`;
