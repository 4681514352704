import { format } from 'date-fns';

export default function (value, time) {
    if (!value) {
        return '';
    }
    const date = new Date(value.replace(' ', 'T'));

    if (time) {
        return format(date, 'dd.MM.yyyy HH:MM:SS');
    }

    return format(date, 'dd.MM.yyyy');
}
