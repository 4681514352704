
export default {
    props: {
        category: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
    data() {
        return {
            promotions: [],
        };
    },
    async fetch() {
        const categoryPromotions = await this.$strapi.getEntries({
            contentType: 'category-promotions',
            filters: {
                categoryName: {
                    $eq: this.category.name,
                },
            },
            populate: ['image', 'link'],
        });
        this.promotions = categoryPromotions.data;
    },
    methods: {
        linkRoute(url) {
            const route = {};

            // eslint-disable-next-line no-magic-numbers
            if (url.includes('?') && url.split('?').length === 2) {
                const [path, queryString] = url.split('?');
                const query = new URLSearchParams(queryString);

                if (query) {
                    route.query = Object.fromEntries(query.entries());
                    route.path = path;
                } else {
                    route.path = url;
                }
            } else {
                route.path = url;
            }

            return route;
        },
    },
};
