
export default {
    computed: {
        backLinkLabel() {
            if (this.$route.path === '/checkout') {
                return this.$t('header.backlinklabel.checkout');
            }

            if (this.$route.path === '/cart') {
                return this.$t('header.backlinklabel.cart');
            }

            if (this.$route.path === '/account/login' || this.$route.path === '/account/requestpassword' || this.$route.path === '/account/register') {
                return this.$t('header.backlinklabel.accountmanagement');
            }

            return this.$t('header.backlinklabel.default');
        },
        backLinkRoute() {
            if (this.$route.path === '/checkout') {
                return '/cart';
            }

            return '/';
        },
        isHanselLogo() {
            if (this.$store.state.auth.customLogoUrl) {
                return false;
            }

            if (this.$config.IS_HANSEL) {
                const isMedituoteCatalog = Boolean(this.$storage.getCookie('medituote-catalog'));

                return !isMedituoteCatalog;
            } else {
                return false;
            }
        },
    },
};
