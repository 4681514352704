
export default {
    head() {
        return {
            script: [
                {
                    type: 'text/javascript',
                    src: 'https://chatbox.cuuma.fi/chat/get_client_js?version=2&_sid=vkqmQismRbq2XbAQXEyj8S2Lw4dS6er00VQK6kIN0pXoAvkmv%2FsFKoET4o42LPJkh9y2ZJUrh8LeuXNt7fGUxQ%3D%3D&meta=',
                    async: true,
                },
            ],
        };
    },
    mounted() {
        if (process.browser) {
            window.ChatConfig = {
                hideLogoWhenOpen: true,
                askNameInitially: true,
                logoClickable: true,
            };

            const chatboxForm = document.getElementById('ChatBoxForm');
            if (chatboxForm) {
                chatboxForm.onclick = () => {
                    const nameInput = document.getElementById('ChatBoxEmailName');
                    const emailInput = document.getElementById('ChatBoxEmailEmail');

                    if (nameInput && emailInput && this.$store.state.auth.loggedIn) {
                        nameInput.value = `${this.$store.state.auth.user.firstname} ${this.$store.state.auth.user.lastname}`;
                        emailInput.value = this.$store.state.auth.user.email;
                    }
                };
            }
        }
    },
};
