import axios from 'axios';
import qs from 'qs';
export default (context, inject) => {
    const MEDITUOTE_STORE_VIEW_CODE = 'default';
    const HANSEL_STORE_VIEW_CODE = 'hansel_hansel_fi';
    const HANSEL_MEDITUOTE_CATALOG_STORE_VIEW_CODE = 'hansel_medituote_fi';

    const { STRAPI_URL, STRAPI_TOKEN } = context.$config || process.env;
    const defaultLocale = context.i18n.locale || undefined;

    const headers = {
        Authorization: `Bearer ${STRAPI_TOKEN}`,
    };
    const strapi = {};

    /*
     * Usage:
     * @contentType: string
     * @locale?: string
     * @fields?: array https://docs.strapi.io/dev-docs/api/rest/populate-select#field-selection
     * @populate?: array https://docs.strapi.io/dev-docs/api/rest/populate-select#relations--media-fields
     * @filters?: array https://docs.strapi.io/dev-docs/api/rest/filters-locale-publication#filtering
     * @sort?: array https://docs.strapi.io/dev-docs/api/rest/sort-pagination#sorting
     *
     * @return Strapi response data
     * */

    const getEntries = async function ({ contentType, locale = defaultLocale, fields, populate, filters, sort, pagination }) {
        const isMedituoteCatalog = Boolean(context.$storage.getCookie('medituote-catalog'));
        const storeView = context.$config.IS_HANSEL
            ? isMedituoteCatalog
                ? HANSEL_MEDITUOTE_CATALOG_STORE_VIEW_CODE
                : HANSEL_STORE_VIEW_CODE
            : MEDITUOTE_STORE_VIEW_CODE;
        const loggedIn = context.store.getters['auth/loggedIn'];
        let allFilters;

        const storeFilter = {
            storeView: {
                $contains: `"${storeView}"`,
            },
        };

        if (loggedIn) {
            const user = context.store.getters['auth/user'];
            const companyId = user.company.company_id;
            const groupId = user.group_id;

            const userFilter = {
                $or: [
                    {
                        visibility: {
                            $contains: `"g${groupId}_"`,
                        },
                    },
                    {
                        visibility: {
                            $contains: `"c${companyId}_"`,
                        },
                    },
                ],
            };

            if (filters) {
                allFilters = {
                    $and: [filters, userFilter, storeFilter],
                };
            } else {
                allFilters = { $and: [userFilter, storeFilter] };
            }
        } else {
            const guestFilter = {
                visibility: {
                    $contains: '"g0_"',
                },
            };

            if (filters) {
                allFilters = {
                    $and: [filters, guestFilter, storeFilter],
                };
            } else {
                allFilters = { $and: [guestFilter, storeFilter] };
            }
        }

        const query = {
            locale,
            fields,
            populate,
            filters: allFilters,
            sort,
            pagination,
        };

        const queryString = qs.stringify(query, { addQueryPrefix: true, encode: false });
        const { data } = await axios.get(`${STRAPI_URL}/${contentType}${queryString}`, {
            headers,
        });

        return data;
    };

    strapi.getEntries = getEntries;

    inject('strapi', strapi);
};
