
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        disableEdit: {
            type: Boolean,
            required: false,
            default: undefined,
        },
        disable: {
            type: Boolean,
            required: false,
            default: undefined,
        },
    },
    data() {
        return {
            qty: this.item.quantity,
        };
    },
    computed: {
        tierPrice() {
            if (this.item?.product && this.item?.product?.price_tiers) {
                const [tier] = this.item.product.price_tiers.filter((price) => price.quantity > this.item.quantity);
                if (tier) {
                    const regularPrice = this.item.product.price_range.minimum_price.regular_price.value;
                    // eslint-disable-next-line no-magic-numbers
                    const discountPercent = Math.round(((regularPrice - tier.final_price.value) / regularPrice) * 100);

                    return this.$t('cart.tier_price', [tier.quantity, discountPercent]);
                }

                return null;
            }

            return null;
        },
        discountPrice() {
            if (this.item?.product && this.item.product.price_range.minimum_price.regular_price.value > this.item.prices.price.value) {
                return this.item.prices.price.value;
            }

            return false;
        },
        discountPercent() {
            if (this.item?.product && this.item.product.price_range.minimum_price.regular_price.value > this.item.prices.price.value) {
                // eslint-disable-next-line no-magic-numbers
                return 100 - ((this.item.prices.price.value / this.item.product.price_range.minimum_price.regular_price.value) * 100).toFixed(0);
            }

            return false;
        },
        manufacturer() {
            if (this.item && this.item.product) {
                const value = this.item.product.custom_attributes.find((attribute) => attribute.attribute_metadata.code === 'manufacturer');

                return value.selected_attribute_options.attribute_option[0].label;
            }

            return '';
        },
        urlKey() {
            return this.item.parent ? this.item.parent.url_key : this.item.product.url_key;
        },
        thumbnail() {
            if (this.item.configured_variant) {
                return this.item.configured_variant.thumbnail;
            }

            if (this.item.parent && this.item.product.thumbnail.url.includes('placeholder/thumbnail')) {
                return this.item.parent.thumbnail;
            }

            return this.item.product.thumbnail;
        },
        isMedituoteBadge() {
            if (this.$config.IS_HANSEL) {
                const isMedituoteCatalog = Boolean(this.$storage.getCookie('medituote-catalog'));

                return isMedituoteCatalog;
            } else {
                return true;
            }
        },
        showFullPrice() {
            if (!this.$store.state.auth.loggedIn || !this.$store.state.auth.user) {
                return true;
            }

            return this.$store.state.auth.user.is_allowed_to_view_regular_price;
        },
        packageUnitSizes() {
            const packageUnits = [];
            if (this.item.product.packaging.packaging_unit_individual && this.item.product.packaging.packaging_amount_individual) {
                packageUnits.push({
                    label: this.item.product.packaging.packaging_unit_individual,
                    size: this.item.product.packaging.packaging_amount_individual,
                });
            }
            if (this.item.product.packaging.packaging_unit_package && this.item.product.packaging.packaging_amount_package) {
                packageUnits.push({
                    label: this.item.product.packaging.packaging_unit_package,
                    size: this.item.product.packaging.packaging_amount_package,
                });
            }

            return packageUnits;
        },
        smallPackageSize() {
            return this.item.product.packaging.packaging_unit_individual || '';
        },
        isCartPage() {
            return this.$route.path === '/cart';
        },
    },
    methods: {
        async update(uid) {
            try {
                // eslint-disable-next-line vue/no-mutating-props
                this.disable = true;
                await this.$store.dispatch('cart/update', {
                    items: [
                        {
                            // eslint-disable-next-line camelcase
                            cart_item_uid: uid,
                            quantity: this.qty,
                        },
                    ],
                });
            } catch (e) {
                if (e.message.includes('The requested qty is not available')) {
                    this.$notify.error('Tuotetta ei valitettavasti ole tällä hetkellä pyydettävää määrää');
                } else {
                    this.$notify.error(e);
                }
            } finally {
                // eslint-disable-next-line vue/no-mutating-props
                this.disable = false;
            }
        },
        async remove(uid) {
            await this.$store.dispatch('cart/removeProduct', {
                uid,
                product: this.item,
            });
        },
    },
};
