export const state = () => ({
    currentProductPreview: '',
    opened: false,
});
export const mutations = {
    setProduct(state, value) {
        state.currentProductPreview = value;
    },
    setOpened(state, value) {
        state.opened = value;
    },
};
export const actions = {
    setProduct({ commit }, value) {
        commit('setProduct', value);
    },
    setOpened({ commit }, value) {
        commit('setOpened', value);
    },
    open({ commit }) {
        commit('setOpened', true);
    },
    close({ commit }) {
        commit('setOpened', false);
    },
};
