const searchPath = '/search';

export default function (req, res, next) {
    if (req && req.originalUrl && req.originalUrl.startsWith(searchPath)) {
        const searchUrlComponents = req.originalUrl.split(searchPath);
        const limitURLComponents = 2;
        if (searchUrlComponents.length < limitURLComponents || searchUrlComponents[1].length === 0) {
            return next();
        }
        const searchQuery = req.originalUrl.substring(searchPath.length);
        if (searchQuery.startsWith('?')) {
            return next();
        }
        try {
            const decodedQuery = decodeURIComponent(searchQuery);
            if (decodedQuery !== searchQuery) {
                // Prevent circular redirects
                const protocol = req.headers.host.startsWith('localhost') ? 'http' : 'https';
                const responseCode = 301;
                res.writeHead(responseCode, {
                    Location: `${protocol}://${req.headers.host}${searchPath}${decodedQuery}`,
                });
                res.end();
            } else {
                return next();
            }
        } catch (e) {
            return next();
        }
    } else {
        return next();
    }
}
