import placeOrderMutation from '@/queries/mutations/placeOrder.graphql';

export const state = () => ({
    order: {},
    success: false,
    loading: false,
});
export const mutations = {
    setSuccess(state, value) {
        state.success = value;
    },
    setOrder(state, value) {
        state.order = value;
    },
    setLoading(state, value) {
        state.loading = value;
    },
};
export const actions = {
    setLoading({ commit }, value) {
        commit('setLoading', value);
    },
    /**
     * @see https://devdocs.magento.com/guides/v2.4/graphql/mutations/place-order.html
     * Create an empty cart
     * Add one or more products to the cart
     * Set the billing address
     * Set the shipping address
     * Set the shipping method
     * Set the payment method
     * For guest customers, assign an email to the cart
     * @returns {Promise<{order_number: *, errors: ReadonlyArray<GraphQLError>}>}
     */
    async placeOrder({ commit }) {
        try {
            const client = this.app.apolloProvider.defaultClient;
            const cartId = this.$storage.getCookie('cart_id');
            /* eslint-disable camelcase */
            const {
                data: {
                    placeOrder: { order: order_number },
                    redirect,
                },
                errors,
            } = await client.mutate({
                mutation: placeOrderMutation,
                variables: { cartId },
            });
            commit('setSuccess', true);

            return { order_number, errors, redirect };
            /* eslint-enable camelcase */
        } catch (e) {
            throw new Error(e.message);
        }
    },
};

export const getters = {
    loading: (state) => state.loading,
};
