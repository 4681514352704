import { gql } from 'graphql-tag';
import CartListFragment from '@/queries/fragments/cartItems.gql.js';

export default gql`
    mutation setCouponOnCart($cartId: String!, $code: String!) {
        applyCouponToCart(input: { cart_id: $cartId, coupon_code: $code }) {
            cart {
                id
                ...CartListFragment
            }
        }
    }

    ${CartListFragment}
`;
