const punchoutPreauthOldPath = 'punchout/login/preAuth';
const punchoutPreauthNewPath = 'punchout/login/index/preAuth';
export default function (req, res, next) {
    if (req && req.url) {
        const isPunchoutPreauth = req.url.includes(punchoutPreauthOldPath) || req.url.includes(punchoutPreauthNewPath);
        const destinationHost = process.env.GRAPHQL_ENDPOINT.replace('/graphql', '');
        if (isPunchoutPreauth) {
            const responseCode = 307;
            res.writeHead(responseCode, {
                Location: `${destinationHost}${req.url}`.replace(punchoutPreauthOldPath, punchoutPreauthNewPath),
            });
            res.end();
        } else {
            return next();
        }
    } else {
        return next();
    }
}
