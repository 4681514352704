const wwwString = 'www.';
const punchoutPreauthOldPath = 'punchout/login/preAuth';
const punchoutPreauthNewPath = 'punchout/login/index/preAuth';
export default function (req, res, next) {
    if (req && req.headers && req.headers.host) {
        const originalHost = req.headers.host;
        const isWWW = originalHost.startsWith(wwwString);
        const isPunchoutPreauth = req.url.includes(punchoutPreauthOldPath) || req.url.includes(punchoutPreauthNewPath);
        const destinationHost = isWWW ? originalHost.substring(wwwString.length) : originalHost;
        if (isWWW) {
            // Temporary redirect with keeping POST data for punchout, permanent for another requests
            const responseCode = isPunchoutPreauth ? 307 : 301;
            res.writeHead(responseCode, {
                Location: `https://${destinationHost}${req.url}`,
            });
            res.end();
        } else {
            return next();
        }
    } else {
        return next();
    }
}
