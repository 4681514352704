export default function (req, res, next) {
    if (req && req.headers) {
        if (!process.env.MAINTENANCE_WHITELIST) {
            return next();
        }

        const isProxied = req.headers['x-forwarded-for'] && req.headers['x-forwarded-for'].length;
        const ip = isProxied ? req.headers['x-forwarded-for'].split(',')[0].trim() : req.connection.remoteAddress || req.socket.remoteAddress;
        const whitelist = process.env.MAINTENANCE_WHITELIST.split(',').map((whitelistItem) => whitelistItem.trim());

        if (req.headers.host && req.headers.host.includes('localhost')) {
            next();

            return;
        }
        const valid = req.headers.host && whitelist.includes(ip);
        if (valid) {
            return next();
        }
        const errorCode = 503;
        res.writeHead(errorCode, { 'Content-Type': 'text/html' });
        res.write('<html><head><meta charset="UTF-8"></head><body><b>Kauppa on huoltotilassa.</b> </body></html>');
        res.end();
    }
}
