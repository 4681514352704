import addToCartMutation from '@/queries/mutations/addProduct.gql.js';
import updateCartMutation from '@/queries/mutations/updateCart.gql.js';
import mutation from '@/queries/mutations/removeItemFromCart.gql.js';
import createEmptyCart from '@/queries/mutations/createEmptyCart.graphql';
import getCart from '@/queries/getCart.gql.js';
import getCustomerCart from '@/queries/getCustomerCart.gql.js';
import setGuestEmailOnCart from '@/queries/mutations/setGuestEmailOnCart.gql.js';
import setShippingAddressOnCart from '@/queries/mutations/setShippingAddressOnCart.gql.js';
import setShippingMethodsOnCart from '@/queries/mutations/setShippingMethodsOnCart.gql.js';
import setBillingAddressOnCart from '@/queries/mutations/setBillingAddressOnCart.gql.js';
// import setAdditionalDataOnCart from '@/queries/mutations/setAdditionalDataOnCart.gql.js';
import setPaymentMethodOnCart from '@/queries/mutations/setPaymentMethodOnCart.gql.js';
import setCouponOnCart from '@/queries/mutations/setCouponOnCart.gql.js';
import removeCouponOnCart from '@/queries/mutations/removeCouponOnCart.gql.js';
import mergeCartsMutation from '~/queries/mutations/mergeCarts.graphql';

export const state = () => ({
    cart: {},
    open: false,
});
export const mutations = {
    setCart(state, value) {
        state.cart = value;
    },
    setOpen(state, value) {
        state.open = value;
    },
};
export const actions = {
    async setCartId(context, id) {
        if (id) {
            return this.$storage.setCookie('cart_id', id, { maxAge: 2592000 });
        }
        const client = this.app.apolloProvider.defaultClient;

        const {
            data: { createEmptyCart: guestCartId },
        } = await client.mutate({ mutation: createEmptyCart });

        return this.$storage.setCookie('cart_id', guestCartId, { maxAge: 2592000 });
    },
    async getCart({ commit }) {
        const client = this.app.apolloProvider.defaultClient;
        const cartId = this.$storage.getCookie('cart_id');
        if (!cartId) {
            return;
        }
        const loggedIn = this.$storage.getCookie('apollo-token');
        const isPunchoutCustomer = Boolean(this.$storage.getCookie('punchout_login'));
        const query = loggedIn && !isPunchoutCustomer ? getCustomerCart : getCart;

        try {
            const {
                data: { cart, customerCart },
            } = await client.query({
                query,
                variables: { id: cartId },
                fetchPolicy: 'no-cache',
            });
            commit('setCart', cart || customerCart);
            this.$storage.setCookie('cart_qty', cart ? cart.total_quantity : customerCart.total_quantity);

            return { cart: cart || customerCart };
        } catch (e) {
            this.$storage.removeCookie('cart_id');
            this.$storage.setCookie('cart_qty', 0);

            return { cart: null };
        }
    },
    async addProduct({ commit, dispatch }, { items, product }) {
        const client = this.app.apolloProvider.defaultClient;
        let cartId = this.$storage.getCookie('cart_id');
        if (!cartId) {
            cartId = await dispatch('setCartId');
        }
        const {
            data: {
                // eslint-disable-next-line camelcase
                addProductsToCart: { user_errors },
            },
        } = await client.mutate({
            mutation: addToCartMutation,
            variables: { items, cartId },
        });

        if (this.$gtm) {
            try {
                this.$gtm.push({ ecommerce: null, items: null });
                this.$gtm.push({
                    event: 'add_to_cart',
                    ecommerce: {
                        currency: 'EUR',
                        items: [
                            {
                                item_id: product.sku,
                                item_name: product.name,
                                price: product.price_range.minimum_price.final_price.value,
                                quantity: items[0].quantity,
                            },
                        ],
                    },
                    value: product.price_range.minimum_price.final_price.value,
                    items: [
                        {
                            id: product.sku,
                            google_business_vertical: 'retail',
                        },
                    ],
                });
            } catch (e) {
                console.error(e);
            }
        }

        const newCart = await dispatch('getCart');

        return { cart: newCart, user_errors };
    },
    async addProducts({ commit, dispatch }, { items, products }) {
        const client = this.app.apolloProvider.defaultClient;
        let cartId = this.$storage.getCookie('cart_id');
        if (!cartId) {
            cartId = await dispatch('setCartId');
        }
        const {
            data: {
                // eslint-disable-next-line camelcase
                addProductsToCart: { user_errors },
            },
        } = await client.mutate({
            mutation: addToCartMutation,
            variables: { items, cartId },
        });

        if (this.$gtm) {
            try {
                const gtmEcommerceItems = products.map((product, i) => {
                    return {
                        item_id: product.sku,
                        item_name: product.name,
                        price: product.price_range.minimum_price.final_price.value,
                        quantity: items[i].quantity,
                    };
                });

                const gtmEventItems = products.map((product) => {
                    return {
                        id: product.sku,
                        google_business_vertical: 'retail',
                    };
                });

                let totalPrice = 0;
                for (let i = 0; i < items.length; i++) {
                    if (products[i]) {
                        totalPrice += products[i].price_range.minimum_price.final_price.value * items[i].quantity;
                    }
                }

                this.$gtm.push({ ecommerce: null, items: null });
                this.$gtm.push({
                    event: 'add_to_cart',
                    ecommerce: {
                        currency: 'EUR',
                        items: gtmEcommerceItems,
                    },
                    value: totalPrice,
                    items: gtmEventItems,
                });
            } catch (e) {
                console.error(e);
            }
        }

        const isPunchoutCustomer = Boolean(this.$storage.getCookie('punchout_login'));
        if (isPunchoutCustomer) {
            await dispatch('updatePunchoutCart');
        }

        const newCart = await dispatch('getCart');

        return { cart: newCart, user_errors };
    },
    async update({ commit, dispatch }, { uid, items }) {
        const client = this.app.apolloProvider.defaultClient;
        let cartId = this.$storage.getCookie('cart_id');
        if (!cartId) {
            cartId = await dispatch('setCartId');
        }

        const {
            data: {
                updateCartItems: { cart },
            },
        } = await client.mutate({
            mutation: updateCartMutation,
            variables: {
                cartId,
                items,
            },
        });

        const isPunchoutCustomer = Boolean(this.$storage.getCookie('punchout_login'));
        if (isPunchoutCustomer) {
            await dispatch('updatePunchoutCart');
        }

        await dispatch('getCart');

        return { cart };
    },
    async removeProduct({ commit, dispatch }, { uid, product }) {
        const client = this.app.apolloProvider.defaultClient;
        const {
            data: {
                removeItemFromCart: { cart },
            },
        } = await client.mutate({
            mutation,
            variables: {
                cartId: this.$storage.getCookie('cart_id'),
                cartItemId: uid,
            },
        });

        if (this.$gtm) {
            try {
                this.$gtm.push({ ecommerce: null });
                this.$gtm.push({
                    event: 'remove_from_cart',
                    ecommerce: {
                        currency: 'EUR',
                        items: [
                            {
                                item_id: product.sku,
                                item_name: product.name,
                                price: product.prices.price_including_tax.value,
                                quantity: product.quantity,
                            },
                        ],
                    },
                });
            } catch (e) {
                console.error(e);
            }
        }

        commit('setCart', cart);

        this.$storage.setCookie('cart_qty', cart.total_quantity);

        const isPunchoutCustomer = Boolean(this.$storage.getCookie('punchout_login'));
        if (isPunchoutCustomer) {
            await dispatch('updatePunchoutCart');
        }

        return { cart };
    },
    async setGuestEmailOnCart({ commit, dispatch }, email) {
        const client = this.app.apolloProvider.defaultClient;
        const cartId = this.$storage.getCookie('cart_id');
        try {
            const {
                data: {
                    setGuestEmailOnCart: { cart },
                },
            } = await client.mutate({
                mutation: setGuestEmailOnCart,
                variables: { email, cartId },
            });

            commit('setCart', cart);

            return { cart };
        } catch (e) {
            throw new Error(e.message);
        }
    },
    async setCouponOnCart({ commit, dispatch }, code) {
        const client = this.app.apolloProvider.defaultClient;
        const cartId = this.$storage.getCookie('cart_id');
        try {
            const {
                data: {
                    applyCouponToCart: { cart },
                },
            } = await client.mutate({
                mutation: setCouponOnCart,
                variables: { cartId, code },
            });

            commit('setCart', cart);

            return { cart };
        } catch (e) {
            throw new Error(e.message);
        }
    },
    async removeCouponOnCart({ commit, dispatch }) {
        const client = this.app.apolloProvider.defaultClient;
        const cartId = this.$storage.getCookie('cart_id');
        try {
            const {
                data: {
                    removeCouponFromCart: { cart },
                },
            } = await client.mutate({
                mutation: removeCouponOnCart,
                variables: { cartId },
            });

            commit('setCart', cart);

            return { cart };
        } catch (e) {
            throw new Error(e.message);
        }
    },
    async setShippingAddressOnCart({ commit, dispatch }, { shipping, billing, notes, addNewAddress, addressId }) {
        const client = this.app.apolloProvider.defaultClient;
        const cartId = this.$storage.getCookie('cart_id');
        const billingAddress = billing ? JSON.parse(JSON.stringify(billing)) : null;
        if (billingAddress) {
            delete billingAddress.vat;
            delete billingAddress.eInvoice;
            delete billingAddress.operator;
            delete billingAddress.email;

            delete billingAddress.__typename;
            delete billingAddress.id;
            delete billingAddress.default_billing;
            delete billingAddress.default_shipping;
            billingAddress.region = '';
            billingAddress.save_in_address_book = false;
        }
        try {
            const shippingAddress = { ...shipping };
            delete shippingAddress.id;
            delete shippingAddress.__typename;
            delete shippingAddress.default_billing;
            delete shippingAddress.default_shipping;
            shippingAddress.region = '';
            shippingAddress.save_in_address_book = false;

            const addressWithNotes = {};

            if (!addNewAddress && addressId) {
                addressWithNotes.customer_address_id = addressId;
                if (notes) {
                    addressWithNotes.customer_notes = notes;
                }
            } else {
                addressWithNotes.address = shippingAddress;
            }

            const billingAddressObject = !addNewAddress && addressId ? { customer_address_id: addressId } : { address: billingAddress };

            await client.mutate({
                mutation: setShippingAddressOnCart,
                variables: { address: [addressWithNotes], cartId },
            });

            const {
                data: {
                    setBillingAddressOnCart: { cart: billingCart },
                },
            } = await client.mutate({
                mutation: setBillingAddressOnCart,
                variables: { address: billingAddressObject, cartId },
            });

            commit('setCart', billingCart);

            return { billingCart };
        } catch (e) {
            throw new Error(e.message);
        }
    },
    async setShippingMethodsOnCart({ commit, dispatch }, shippingMethods) {
        const client = this.app.apolloProvider.defaultClient;
        const cartId = this.$storage.getCookie('cart_id');
        try {
            const {
                data: {
                    setShippingMethodsOnCart: { cart },
                },
            } = await client.mutate({
                mutation: setShippingMethodsOnCart,
                variables: { shippingMethods, cartId },
            });

            commit('setCart', cart);

            return { cart };
        } catch (e) {
            throw new Error(e.message);
        }
    },
    async setPaymentMethodOnCart({ commit, dispatch }, paymentMethod) {
        const client = this.app.apolloProvider.defaultClient;
        const cartId = this.$storage.getCookie('cart_id');
        try {
            const {
                data: {
                    setPaymentMethodOnCart: { cart },
                },
            } = await client.mutate({
                mutation: setPaymentMethodOnCart,
                variables: { paymentMethod, cartId },
            });

            commit('setCart', cart);

            return { cart };
        } catch (e) {
            throw new Error(e.message);
        }
    },
    async mergeCarts({ commit, dispatch }, oldId) {
        if (oldId) {
            const isPunchoutCustomer = Boolean(this.$storage.getCookie('punchout_login'));
            if (isPunchoutCustomer) {
                return;
            }

            const client = this.app.apolloProvider.defaultClient;

            const newId = this.$storage.getCookie('cart_id');
            try {
                await client.mutate({
                    mutation: mergeCartsMutation,
                    variables: {
                        source: oldId,
                        destination: newId,
                    },
                });
            } catch (e) {
                console.error(e);
            }
        }
    },
    openCart({ commit, dispatch }, value) {
        commit('setOpen', value);
    },
    async updatePunchoutCart({ commit, dispatch }) {
        let cart = await dispatch('getCart');
        if (cart && cart.cart) {
            const cartHasAddress =
                cart.cart.shipping_addresses && cart.cart.shipping_addresses[0] && cart.cart.shipping_addresses[0].available_shipping_methods;
            if (!cartHasAddress) {
                const emptyAddress = { street: '-', firstname: '-', lastname: '-', country_code: 'FI', city: '-', postcode: '-', telephone: '-' };
                await dispatch('setShippingAddressOnCart', {
                    shipping: emptyAddress,
                    billing: emptyAddress,
                });

                cart = await dispatch('getCart');
            }
            const shippingMethods = [...cart.cart.shipping_addresses[0].available_shipping_methods];
            const hasFreeShipping = shippingMethods.map((method) => method.method_code).includes('freeshipping');
            const selectedShippingMethod = hasFreeShipping
                ? shippingMethods.find((method) => method.method_code === 'freeshipping')
                : shippingMethods.find((method) => method.method_code === 'medituoteshipping');
            /* eslint-disable camelcase */
            const { method_code, carrier_code } = selectedShippingMethod;
            const method = {
                method_code,
                carrier_code,
            };
            /* eslint-enable camelcase */
            await dispatch('setShippingMethodsOnCart', [method]);
            await dispatch('getCart');
        }
    },
};
export const getters = {
    open: (state) => state.open,
};
